@font-face {
  font-family: 'OpenSans';
  src: url(quote('https://alix-static-files.s3.ca-central-1.amazonaws.com/fonts/OpenSans-Regular.woff')) format(quote('woff'));
}

@font-face {
  font-family: 'Segoe UI';
  src: url(quote('https://alix-static-files.s3.ca-central-1.amazonaws.com/fonts/Segoe+UI.ttf')) format(quote('truetype'));
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: a-get-color(primary, normal);
  border-radius: 4px;
}

html,
body,
#root {
  $a-font-size: 14px;
  --smart-pin-button-height: 2.4rem;

  overflow: hidden;
  background: a-get-color(primary, darker);
  color: a-get-color(basic, brightest);
  font-family: "Segoe UI", "OpenSans";
  font-size: $a-font-size;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  &:has(.a-app-content-container.a-responsive-font) {
    font-size: $a-font-size * 0.7;

    @media screen and (min-width: a-get-media(m-min)) {
      font-size: $a-font-size * .8;
    }
  
    @media screen and (min-width: a-get-media(l-min)) {
      font-size: $a-font-size;
    }
  }
}

.a-app-container {
  --a-app-header-height: 4rem;

  display: flex;
  flex-direction: column;
  height: 100%;

  .p-toast {
    z-index: 1000;
  }
}

.a-app-content-container {
  display: flex;
  flex: 1;
  overflow: auto;
}

.a-app-content {
  .a-smart-messages {
    top: 0;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    width: auto;
    z-index: 99;

    div {
      margin: 0;
    }

    svg {
      font-size: a-get-font-size(l);
      padding-right: 0.3rem;
    }

    .p-message-wrapper {
      min-height: 4rem;
    }
  }

  @media screen and (min-width: a-get-media(m-min)) {
    &:has(.a-grid-page-wrapper.a-wrapped) .a-smart-messages {
      --grid-navigation-width: 250px;
      --content-container-width: calc(100% - var(--grid-navigation-width) + 2 * var(--default-padding)); 
      width: var(--content-container-width);
      left: var(--grid-navigation-width);
    }
    &:has(.a-grid-page-wrapper.a-wrapped.a-app-flush-content) .a-smart-messages {
      --default-padding: 0px;
    }
  }

  &:has(.a-front-iframe-wrapper.a-hidden):not(:has(iframe.a-dashboard-iframe)),
  &:not(:has(.a-front-iframe-wrapper)) {
    --default-padding: 1.2rem;
    .a-smart-messages {
      top: calc(-1 * var(--default-padding));
      margin-left: calc(-1 * var(--default-padding));
      margin-right: calc(-1 * var(--default-padding));
    }
  }

  &:has(.a-front-iframe-wrapper:not(.a-hidden)) {
    display: flex;
    flex-direction: column;
  }

  flex: 1;
  overflow: auto;

  &:not(:has(.a-app-flush-content)) {
    padding: var(--default-padding);
  }
}

iframe.a-front-iframe {
  display: flex;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

a,
a:active,
a:hover,
a:visited {
  color: a-get-color(basic, brightest);
  text-decoration: none;
}

.a-flex {
  display: flex;

  &.a-stretch {
    flex: 1;
  }

  &.a-column {
    flex-direction: column;
  }

  &.a-align-center {
    align-items: center;
  }

  &.a-justify-center {
    justify-content: center;
  }

  &.a-justify-between {
    justify-content: space-between;
  }

  &.a-justify-end {
    justify-content: flex-end;
  }

  &.a-gap {
    gap: 0.6rem;
  }

  &.a-wrap {
    flex-wrap: wrap;
  }
}

// TODO (bzoretic): remove the following duplicates and use the above flex classes instead
.a-flex-column {
  display: flex;
  flex-direction: column;
}

.a-flex-center {
  display: flex;
  align-items: center;

  &.a-gap {
    gap: 0.6rem;
  }
}

.a-flex-end {
  display: flex;
  align-items: flex-end;
}

.a-justify-center {
  display: flex;
  justify-content: center;
}

.a-no-focus {
  &:focus {
    outline: none;
  }
}

.a-hidden {
  display: none !important;
}

.a-green {
  color: a-get-color(green, normal);
}

.a-red {
  color: a-get-color(red, normal);
}

.a-secondary {
  color: a-get-color(secondary, normal);
}

.a-white {
  color: a-get-color(basic, brightest);
}

.a-darkest {
  color: a-get-color(primary, darkest);
}

.a-yellow {
  color: a-get-color(yellow, normal);
}

.a-semi-bold {
  font-weight: a-get-font-weight(semibold);
}

.a-trash-button {
  color: a-get-color(red, normal);
  cursor: pointer;
}

.a-default-value {
  color: a-get-color(primary, brightest);
}

.a-pre-wrap {
  word-break: break-word;
  white-space: pre-wrap;

  &.a-before:before {
    content: '\a';
  }

  &.a-after:after {
    content: '\a';
  }
}

.a-no-wrap {
  white-space: nowrap;
}

.p-inputnumber-button-up.p-button,
.p-inputnumber-button-down.p-button {
  background: a-get-color(primary, darkest);
  color: a-get-color(secondary, normal);
  border-color: a-get-color(primary, bright);

  &:not(a):not(.p-disabled):hover {
    background: a-get-color(hover, normal);
    color: a-get-color(secondary, normal);
    border-color: a-get-color(primary, bright);
  }
}

.p-inputnumber-buttons-horizontal {
  .p-inputnumber-button-up {
    border-left: 0;
  }

  .p-inputnumber-button-down {
    border-right: 0;
  }
}

.a-custom-toast-content {
  display: flex;
  width: 100%;
}

.a-spin {
  -webkit-animation: fa-spin 1s infinite cubic-bezier(0.22, 0.61, 0.28, 0.94);
  animation: fa-spin 1s infinite cubic-bezier(0.22, 0.61, 0.28, 0.94);
}

.a-grid-page-wrapper {
  --grid-navigation-width: 250px;
  --content-container-width: calc(100% - var(--grid-navigation-width));

  display: flex;
  justify-content: flex-end;
  height: 100%;

  &.a-wrapped {
    height: fit-content;
    min-height: 100%;
  }
}

.a-grid-page-content-container {
  width: var(--content-container-width);
  height: 100%;
  overflow: auto;

  .a-page-header-container {
    margin: 0;
    width: auto;
  }

  .a-smart-grid-menu-content {
    margin: var(--default-padding)
  }

  .a-tare-container .a-tare-content-container {
    margin-top: 0;
  }
}

.a-grid-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  &:not(.a-smart-grid-menu) {
    width: 100%;
  }

  .p-datatable-tbody tr:not(.p-datatable-emptymessage):not(.a-row-disabled) {
    cursor: pointer;
  }
}

.p-toast-detail {
  white-space: pre-wrap;

  .a-toast-detail-link {
    color: inherit;
    text-decoration: underline;
  }
}

.a-clickable-link {
  cursor: pointer;
  text-decoration: underline;

  &.a-required {
    color: a-get-color(red, normal);
  }

  &:hover {
    color: a-get-color(secondary, normal);
  }
}

.a-smart-menu-overlay {
  position: fixed;
  top: 4rem;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 99;
}

.a-navigation-link {
  cursor: pointer;
  color: a-get-color(blue, normal) !important;
}

.a-measure-clickable-link {
  &:not(.a-measure-clickable-link-label) {
    cursor: pointer;
    text-decoration: underline;
  }

  &:not(.a-measure-clickable-link-label):hover {
    opacity: 0.7;
  }
}

.a-semi-bold {
  font-weight: a-get-font-weight(semibold);
}

.a-invisible {
  visibility: hidden;
}

.a-font-reset {
  font-size: initial !important;
  font-weight: initial !important;
}

.a-docraptor-print-table,
.a-docraptor-print-table .a-smart-document-container,
.a-docraptor-print-table .p-datatable-table {
  border: 0;
  width: 100%;
}

.a-docraptor-print-table>thead div {
  justify-content: space-between;
  padding: 0 0.2in;
  color: #000;
  margin-top: -0.2in;
}

.a-disabled {
  opacity: 0.4;
  cursor: default !important;
  color: #d5d5d5;
  &.a-no-action {
    pointer-events: none;
  }
}

.p-datepicker-other-month {
  opacity: 0.6;
}

.p-multiselect-item svg.p-icon {
  pointer-events: none;
}

.a-error {
  &:not([data-is-error="true"]) {
    height: 0px;
    padding: 0;
    visibility: hidden;
  }

  font-size: a-get-font-size(m);
  padding: 0.3rem 0;
  color: a-get-color(red, normal);
}

#zohohc-asap-web-launcherbox {
  display: none;
}

.a-smart-multiselect-toggle-panel {
  width: fit-content;
  min-width: 20rem;

  &.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    margin: 0;
  }

  .p-multiselect-close {
    display: none;
  }
}

.a-smart-multiselect-toggle {
  .p-multiselect-label-container {
    display: none;
  }
}

.a-smart-dropdown-grouped {
  .p-dropdown-item {
    padding-left: 2rem !important;
  }
}

.a-page-header-action-buttons {
  display: flex;
  align-items: center;

  .a-page-header-action-button {
    margin: 0;
    border: 1px solid a-get-color(primary, bright);
    border-right: 0;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid a-get-color(primary, bright);
    }

    &:hover:not(:last-child) {
      border-right: 0;
    }
  }
}

.a-page-header-action-button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid a-get-color(primary, bright);
  font-weight: a-get-font-weight(semibold);
  padding: 0 0.6rem;
  margin: 0 0.6rem;
  height: 2.5rem;
  user-select: none;
  cursor: pointer;

  svg:last-child:not(:first-child) {
    margin-left: 0.6rem;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover:not(.a-disabled) {
    background: a-get-color(hover, 'normal');
    border: 1px solid a-get-color(primary, bright);
  }


  &.a-primary {
    background: a-get-color(secondary, normal);
    color: a-get-color(primary, darker);
    border: 1px solid a-get-color(secondary, normal);

    &:hover {
      background: a-get-color(secondary, bright);
      border: 1px solid a-get-color(secondary, bright);
    }
  }
}

.a-double-confirmation-checkbox-container {
  display: flex;
  gap: 0.6rem;
  padding: 0.6rem;
  border: 1px solid a-get-color(primary, bright);
  background-color: a-get-color(primary, darker);
  color: a-get-color(basic, bright);
  border-radius: 0.3rem;
}

.a-line-seperator {
  border-top: 1px solid a-get-color(primary, bright);
  margin: 0.3rem 0;
}

.ql-editor {
  font-size: 12px;
  font-family: Verdana, Geneva, sans-serif;
  margin-top: -1px;
  padding: 1px .2em 0;
  word-wrap: break-word;
  -webkit-nbsp-mode: space;
  -webkit-line-break: after-white-space;
  padding: 16px;
  box-sizing: border-box;
}

.a-green-button, .a-green-button.a-smart-button  {
  color: a-get-color(green, normal);
  background: a-get-color(green, bright);
  border-color: a-get-color(green, bright);

  &:not(:disabled):hover {
    color: a-get-color(basic, brightest);
    background: a-get-color(green, normal);
    border-color: a-get-color(green, normal);
  }
}

.a-red-button, .a-red-button.a-smart-button {
  color: a-get-color(red, normal);
  background: a-get-color(red, brighter);
  border-color: a-get-color(red, brighter);

  &:not(:disabled):hover {
    color: a-get-color(basic, brightest);
    background: a-get-color(red, normal);
    border-color: a-get-color(red, normal);
  }
}

.a-full-width {
  width: 100%;
}

.a-app-content .p-scrolltop-sticky {
  position: fixed;
}